export const PRODUCT_ENTITY_INFO_FRAGMENT = `
  fragment productEntityInfoFragment on Product {
    uid
    schemaCode
    name
    dimensions
    manufacturer
    model
    serie
    serialNumber
    price
    images {
      fullFileResource {
        uid
        path
        schemaCode
      }
    }
    exhibitor {
      uid
      editionMappings {
        editionExhibitor {
          schemaCode
          booths {
            schemaCode
            number
            exhibitHall {
              id
              uid
              name
            }
          }
        }
      }
    }
  }
`;
